import React, { useEffect, useState } from 'react';
import "../css/Home.css";
import { Link } from 'react-router-dom';
import Button from '../components/Button';
import Introduction from '../components/introduction';
import RoleCards from '../components/role-cards';
import Attendees from '../components/attendees';
import SpeakerCard from '../components/SpeakerCard';
import SpeakerCardM from '../components/SpeakerCardM';
import PreviousSponsors from '../components/PreviousSponsors';
import MediaPartners from '../components/MediaPartners';
import WhoWillBeThere from '../components/WhoWillBeThere';

const Home = ({selectedIndex}) => {
  const [mobile, setMobile] = useState(true);

  useEffect(() => {
    const handleSize = () => {
      if (window.innerWidth < 642) {
        setMobile(true);
      }
      else {
        setMobile(false)
      }
    }

    if (window.innerWidth < 642) {
      setMobile(true);
    }
    else {
      setMobile(false)
    }

    window.addEventListener("resize", handleSize);


    return () => {
      window.removeEventListener("resize", handleSize);
    }



  }, [])
  return (
    <>
      <section className='Container mx-8 2xl:mx-auto'>
        <div className='relative overflow-x-hidden w-full h-[60vh] lg:h-[80vh] rounded-[40px] mt-10 hero-video'>
          <div className='video-background pointer-events-none rounded-30 w-full h-full overflow-hidden opacity-35 relative'>
            {/* <iframe src="https://player.vimeo.com/video/882775811?background=1" frameBorder="0" allowFullScreen title='main-video'></iframe> */}
            <iframe src="https://www.youtube.com/embed/IXSl-7PvrIM?playlist=IXSl-7PvrIM&loop=1&autoplay=1&mute=1" frameBorder="0" allowFullScreen title='main-video'></iframe>
          </div>
          <div className="comingsoon-inner" style={{ top: "55%" }}>
            <div className="comingsoon-content h-full w-[60vw] mx-auto">
              <p className='text-2xl sm:text-3xl md:text-4xl lg:text-6xl font-gillRoy900'>Meet The Forex Universe Revolution</p>
            </div>
          </div>
        </div>
        <div className='hero-text text-center mt-12 mb-20'>
          <h3 className='flex items-center flex-col lg:block text-lg font-gillRoy700 text-white'>
            {/* 20 - 21 February 2024 */}
            <span className='font-gillRoy400 text-maincolor'> The Ritz-Carlton, Dubai</span>
            <Button className="button font-gillRoy700 btn-class text-white ms-6 w-fit">Become Sponsor</Button>
          </h3>
        </div>
      </section>
      <Introduction />
      <Attendees />
      <WhoWillBeThere />
      <RoleCards />
      {
        mobile ? <SpeakerCardM /> : <SpeakerCard />
      }
      <PreviousSponsors selectedIndex = {selectedIndex}/>
      {/* <CommunityPartners /> */}
      <MediaPartners />
      {/* <EventTable selectedIndex = {selectedIndex}/> */}
    </>
  )
}

export default Home