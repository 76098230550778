import React, { useEffect, useState } from "react";
import ImageField from "./ImageField";
import introductionImage from "../assests/media/intoductionImage.png"
import blurredsvg from "../assests/svgs/blurredsvg.svg"

function Introduction() {
  const [showmob, setShowMob] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", function () {
      setShowMob(window.innerWidth);
    });

    return () => {
      window.removeEventListener("resize", function () {
        setShowMob(window.innerWidth);
      });
    };
  }, []);

  return (
    <>
      <section id="what-is-crypto" className="relative ">
        <div className="w-11/12 2xl:max-w-7xl mx-auto flex flex-col gap-11 rounded-30 bg-introgradient backdrop-blur-3xl shadow-sm p-6 md:p-8 xl:p-14 z-20">
          <div className=" flex w-full items-start flex-col md:flex-row gap-8 lg:gap-12 xl:gap-16">
            <div className="flex flex-col gap-5 md:gap-12 justify-start w-full flex-1">
              <h1 className="flex flex-col gap-1 justify-start text-maincolor font-gillRoy700 text-4xl md:text-5xl lg:text-7xl uppercase">
                <span className="text-lg md:text-2xl lg:4xl">what to expect at</span>
                forex306?
              </h1>
              <p className="font-gillRoy600 text-white text-xl md:text-2xl xl:text-3xl tracking-wide">
                Dubai’s premier gathering of global Forex Ecosystem
              </p>            
            </div>

            <div className=" flex justify-end  rounded-30">
                <ImageField taggedElem={introductionImage} altText="error-introduction" className="md:w-[450px] xl:w-[550px] opacity-80 bg-blend-luminosity " />
            </div>
          </div>
            <p className="text-white font-gillRoy400 tracking-wide text-justify text-base md:text-xl select-none">Forex306 is the premier gathering of global Forex traders, fund managers, IBs, investors, financial institutions, and brokers across the entire trading and investing landscape. Our event offers a prime opportunity for you to forge meaningful connections within the industry. Get ready to immerse yourself in an interactive platform where you can engage with top-notch service providers, stay updated on the latest market trends, and gain invaluable financial insights. By participating in Forex306, you will enhance your skills, expand your network, and effectively navigate the ever-evolving investment terrain.</p>
        </div>
        {/* <ImageField taggedElem={blurredsvg} altText="error-bluredimg" className="absolute -top-[65%] left-0 -z-10"/> */}
      </section>
    </>
  );
}

export default Introduction;
