import React from 'react';
import ForexRetail from '../assests/svgs/ForexRetail.svg';
import Investors from '../assests/svgs/Investors.svg';
import Technology from '../assests/svgs/Technology.svg';
import PaymentSolutionProviders from '../assests/svgs/PaymentSolutionProviders.svg';
import TradingPlatform from '../assests/svgs/TradingPlatform.svg';
import TrainingInstitution from '../assests/svgs/TrainingInstitution.svg';
import MediaAndMarketingAgency from '../assests/svgs/MediaAndMarketingAgency.svg'
import ComplianceAdvisoryAgency from '../assests/svgs/ComplianceAdvisoryAgency.svg'




function WhoWillBeThere() {
    const exicutive = [
        {
            ExicutiveIcon: ForexRetail,
            ExicutiveName: "Forex Retail/ Institutional Broker",
        },
        {
            ExicutiveIcon: Investors,
            ExicutiveName: "Investor/ iBs/ Fund Manager",
        },
        {
            ExicutiveIcon: Technology,
            ExicutiveName: "Technology Solution/ Liquidity Providers",
        },
        {
            ExicutiveIcon: PaymentSolutionProviders,
            ExicutiveName: "Payment Solution providers",
        },
        {
            ExicutiveIcon: TradingPlatform,
            ExicutiveName: "Copy Trading Platform",
        },
        {
            ExicutiveIcon: TrainingInstitution,
            ExicutiveName: "Education training institution",
        },
        {
            ExicutiveIcon: MediaAndMarketingAgency,
            ExicutiveName: "financial media and marketing agency",
        },
        {
            ExicutiveIcon: ComplianceAdvisoryAgency,
            ExicutiveName: "compliance advisory agency",
        }
    ]
    return (
        <>
            <section id='Targeted-Audience' className='pb-16 scroll-m-8'>
                <h1 className='text-center text-white uppercase text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-gillRoy700 pt-14 pb-6 sm:py-12 md:pt-20 md:pb-10 lg:pt-[100px] lg:pb-[30px]'>Targeted Audience</h1>
                <p className="md:max-w-[1040px] max-w-[500px] mx-auto text-center text-maincolor mb-8 px-4 text-xl">Forex306 is the prime destination for senior executives and decision-makers to close deals. Regardless of your business specialisation, this is where you can secure your future business partnerships.</p>
                <div className='px-4'>
                    <div className="md:max-w-[1080px] max-w-[500px] mx-auto m-3 bg-introgradient backdrop-blur-3xl shadow-2xl py-7 px-5 rounded-30">
                        <div className="grid grid-cols-2 md:grid-cols-4" >
                            {exicutive.map((element, key) => (
                                <div className="text-center mx-auto  w-36 py-4" key={key}>
                                    <img src={element.ExicutiveIcon} alt="" className="mx-auto w-10" />
                                    <p className='text-white font-gillRoy600 text-sm mt-2 mb-4 capitalize'>{element.ExicutiveName}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default WhoWillBeThere
