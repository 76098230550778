import React from 'react';
import Button from './Button';
import ImageField from './ImageField';
import attendeesBg from '../assests/svgs/attendeesBg.svg'
import attendees1 from '../assests/media/attendees1.jpg';
import attendees2 from '../assests/media/attendees2.jpg';
import attendees3 from '../assests/media/attendees3.jpg';
import attendees4 from '../assests/media/attendees4.jpg';
import attendees5 from '../assests/media/attendees5.jpg';


function Attendees() {

    const ateendees = [
        {
            ateendees: attendees1,
            count: '3,000+',
            category: "Attendees"
        },
        {
            ateendees: attendees1,
            count: '200+',
            category: "Companies"
        },
        {
            ateendees: attendees2,
            count: '81+',
            category: "Speakers"
        },
        {
            ateendees: attendees3,
            count: '60+',
            category: "Sponsors"
        },
        {
            ateendees: attendees4,
            count: '92+',
            category: "Media"
        },
        {
            ateendees: attendees5,
            count: '50+',
            category: "Partners"
        },
    ];

    return (
        <>
            <section id='attendees' className='mt-20'>
                <div className='xsm flex justify-evenly gap-y-6 sm:gap-2 items-center flex-wrap sm:flex-nowrap sm:px-5  md:justify-center md:gap-5'>
                    {
                        ateendees.map((element, index) => (
                            <div className='ateendees-card group relative rounded-2xl overflow-hidden w-[200px] sm:w-[120px] h-[374px] duration-500 ease-in-out sm:hover:w-[224px] sm:hover:h-[380px]' key={index}>
                                <ImageField taggedElem={element.ateendees} altText="" className="absolute w-[224px] h-full object-cover object-left z-10 " />
                                <ImageField taggedElem={attendeesBg} altText="" className="absolute z-30 bottom-0" />
                                <div className='card-text  relative z-30 top-5 duration-500 ease-in-out group-hover:rotate-0 group-hover:transition-all group-hover:ease-in-out'>
                                    <h3 className='font-gillRoy700 text-3xl text-white relative w-fit left-[45%] -translate-x-[55%] -rotate-90 transition-all duration-500 group-hover:left-[50%] group-hover:-translate-x-[50%]'>{element.count}</h3>
                                    <p className='font-gillRoy200 text-3xl text-white relative w-fit left-[55%] -translate-x-[45%] -rotate-90 transition-all duration-500'>{element.category}</p>
                                </div>
                                <Button className='read-more absolute z-40 bottom-[42px] flex gap-2 items-center justify-center w-full'>
                                    <label className='font-gillRoy400 text-white text-[0px] duration-500 ease-in-out group-hover:text-[15px] group-hover:block'>Read more</label>
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className='duration-500 ease-linear group-hover:rotate-180 group-hover:scale-[50%]'>
                                        <path d="M16.6698 6.7445L4.30995 0.319571C3.90631 0.107035 3.44877 -0.00320241 2.98388 7.08247e-05C2.51899 0.00334406 2.06333 0.120012 1.66326 0.338206C1.27349 0.543597 0.949669 0.840383 0.72469 1.19841C0.499711 1.55644 0.38159 1.96296 0.382328 2.37667V15.2265C0.38159 15.6402 0.499711 16.0467 0.72469 16.4048C0.949669 16.7628 1.27349 17.0596 1.66326 17.265C2.06337 17.4831 2.51901 17.5997 2.98388 17.603C3.44875 17.6062 3.90627 17.496 4.30995 17.2836L16.6698 10.8587C17.0684 10.6552 17.4007 10.3572 17.632 9.99541C17.8633 9.63367 17.9853 9.22146 17.9853 8.80159C17.9853 8.38173 17.8633 7.96952 17.632 7.60777C17.4007 7.24603 17.0684 6.94795 16.6698 6.7445Z" fill="white" />
                                    </svg>
                                </Button>
                            </div>
                        ))
                    }
                </div>
            </section>
        </>
    )
}

export default Attendees
