import React, { useEffect, useState } from 'react'
import Button from './Button'
import Table from './Table'

const tableHeading = [
    "", "Topic", "Speaker", "Company", "Time"
]

const tableRows = [
    {
        topic: "Indroduction Business",
        speaker: "Vaibhav Ali",
        company: "Web3 Travels",
        time: "10:05 AM - 10:15AM"
    },
    {

        topic: "Indroduction Business",
        speaker: "Mustafa",
        company: "Web3 Travels",
        time: "10:05 AM - 10:15AM"
    },
    {
        topic: "Indroduction Business",
        speaker: "Adel Bhurtun",
        company: "Web3 Travels",
        time: "10:05 AM - 10:15AM"
    },
    {
        topic: "Indroduction Business",
        speaker: "Jason Singh",
        company: "Web3 Travels",
        time: "10:05 AM - 10:15AM"
    },
    {
        topic: "Indroduction Business",
        speaker: "Fastex",
        company: "Web3 Travels",
        time: "10:05 AM - 10:15AM"
    },
    {
        topic: "Indroduction Business",
        speaker: "Md Khasheg ji",
        company: "Web3 Travels",
        time: "10:05 AM - 10:15AM"
    }

]


const tableRows2 = [
    {
        topic: "Indroduction Business",
        speaker: "MUbeen UI/UX",
        company: "Web3 Travels",
        time: "10:05 AM - 10:15AM"
    },
    {

        topic: "Indroduction Business",
        speaker: "Vatsal FD",
        company: "Web3 Travels",
        time: "10:05 AM - 10:15AM"
    },
    {
        topic: "Indroduction Business",
        speaker: "RAJ FD",
        company: "Web3 Travels",
        time: "10:05 AM - 10:15AM"
    },
    {
        topic: "Indroduction Business",
        speaker: "Jason Singh",
        company: "Web3 Travels",
        time: "10:05 AM - 10:15AM"
    },
    {
        topic: "Indroduction Business",
        speaker: "Fastex",
        company: "Web3 Travels",
        time: "10:05 AM - 10:15AM"
    },
    {
        topic: "Indroduction Business",
        speaker: "Md Khasheg ji",
        company: "Web3 Travels",
        time: "10:05 AM - 10:15AM"
    }
]

const dayButtons = [
    {
        text: "Day 1",
        spanText: "20 Feb, 2024"
    },
    {
        text: "Day 2",
        spanText: "21 Feb, 2024"
    }
]

const EventTable = ({selectedIndex}) => {
    const viewPoint = document.getElementById("eventTable");

    if(selectedIndex === 4){
        viewPoint.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    const defaultItems = 2;
    const [nextItems, setNextItems] = useState(defaultItems)

    const [defaultDay, setDefaultDay] = useState(0);

    const [screenSize, setScreenSize] = useState(window.innerWidth);


    const handleMoreData = () => {
        setNextItems(tableRows.length);
    }

    const handleLessData = () => {
        setNextItems(defaultItems)
    }

    const handleDefaultDay = (index) => {
        setDefaultDay(index);
    }

    useEffect(() => {
        window.addEventListener("resize", function () {
            setScreenSize(window.innerWidth);
        })

        window.removeEventListener("resize", function () {
            setScreenSize(window.innerWidth);
        })
    }, [])

    return (
        <>
            <section id="eventTable">
                <div className="w-full flex justify-center flex-col items-center">
                    <div className=" w-5/6 flex items-center largeTab:items-baseline gap-12 largeTab:gap-0 largeTab:justify-between flex-col largeTab:flex-row mt-[100px] mb-16">
                        <span className='capitalize font-gillRoy700 text-7xl bg-clip-text bg-gradient-to-r from-cyan-500 to-cyan-200 text-transparent h-20 '>agenda</span>
                        <div className="right flex gap-5">
                            {
                                dayButtons.map((element, key) => (
                                    <div className='flex flex-col items-center' key={key}>
                                        <Button className={`${key !== defaultDay ? 'bg-gradient-to-r from-cyan-500 to-cyan-200 text-white px-11 py-3 largeTab:py-[12.0px] largeTab:px-[54.01px]' : 'bg-white border-4 border-buttonborder text-maincolor box-border px-10 py-[8.5px] largeTab:py-2 largeTab:px-[51.72px] shadow-unselectionbuttonshadow'} font-gillRoy600 text-22 rounded-20   `} onClick={() => handleDefaultDay(key)}>{element.text}</Button>
                                        <span className={` ${key === defaultDay ? "text-tabletext bg-tablebg shadow-unselectshadow" : "text-maincolor bg-tableunselect "}  font-gillRoy700 text-xs px-4  largeTab:w-28  py-1  rounded-b-lg text-center`}>{element.spanText}</span>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                    {/* table area starts from here desktop  */}
                    {
                        screenSize > 919 ?
                            <>
                               <Table tableRows={tableRows} tableRows2={tableRows2} defaultDay={defaultDay} tableHeading = {tableHeading} defaultItems={defaultItems}/>
                            </> :
                            <>
                                <div className="tablemobile">
                                    <div className="flex flex-col w-screen items-center px-2 sm:px-0">

                                        {
                                            (defaultDay === 0 ? tableRows : tableRows2)?.slice(0, nextItems)?.map((element, key) => (
                                                <div className='flex gap-8 bg-tablebg mb-3 py-3 pl-3 pr-12 rounded-xl'>

                                                    <div className='flex items-center justify-center px-2 py-4 rounded-xl bg-maincolor text-white'>{key + 1}</div>

                                                    <div className='flex flex-col justify-center gap-2'>
                                                        <span className="font-gillRoy700 text-black text-xs">{element.topic}</span>
                                                        <span className='text-tablefontmobilecolor font-gillRoy700 text-xs uppercase'>{element.company}</span>
                                                    </div>

                                                    <div className='flex flex-col  justify-center gap-2'>
                                                        <span className='text-maincolor font-gillRoy600 text-base'>{element.speaker}</span>
                                                        <span className='text-tablefontmobilecolor font-gillRoy700 text-xs'>{element.time}</span>
                                                    </div>

                                                </div>
                                            ))
                                        }
                                        {nextItems === defaultItems ?
                                            <>

                                                <Button className="capitalize bg-maincolor text-white font-gillRoy700 rounded-30 py-2 px-5 text-base float-right mt-7  max-w-fit hover:text-buttonhover" onClick={handleMoreData}>load more</Button>
                                            </> :
                                            <>
                                                <Button className="capitalize bg-maincolor text-white font-gillRoy700 rounded-30 py-2 px-5 text-base float-right mt-8  max-w-fit hover:text-buttonhover" onClick={handleLessData}>show less</Button></>
                                        }

                                    </div>
                                </div>
                            </>
                    }
                </div>
            </section>
        </>
    )
}

export default EventTable
