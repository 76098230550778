import React, { useEffect, useState } from 'react';
import "../css/Home.css";
import Button from '../components/Button';
// import Introduction from '../components/introduction';
import RoleCards from '../components/role-cards';
import Attendees from '../components/attendees';
import Partners from '../components/partners';
import SpeakerCard from '../components/SpeakerCard';
import SpeakerCardM from '../components/SpeakerCardM';
import PreviousSponsors from '../components/PreviousSponsors';
import EventTable from '../components/EventTable';
import CommunityPartners from '../components/CommunityPartners';
import MediaPartners from '../components/MediaPartners';

const Dubai = ({selectedIndex}) => {
  const [mobile, setMobile] = useState(true);

  useEffect(() => {
    const handleSize = () => {
      if (window.innerWidth < 642) {
        setMobile(true);
      }
      else {
        setMobile(false)
      }
    }

    if (window.innerWidth < 642) {
      setMobile(true);
    }
    else {
      setMobile(false)
    }

    window.addEventListener("resize", handleSize);


    return () => {
      window.removeEventListener("resize", handleSize);
    }



  }, [])
  return (
    <>
      <section className='Container mx-8 2xl:mx-auto'>
        <div className='relative overflow-x-hidden w-full h-[60vh] lg:h-[80vh] rounded-[35px] mt-10 hero-video p-2'>
          <div className='video-background pointer-events-none rounded-30 w-full h-full overflow-hidden brightness-[.4]'>
            <iframe src="https://player.vimeo.com/video/882775811?background=1" frameBorder="0" allowFullScreen title='main-video'></iframe>
          </div>
          <div className="comingsoon-inner" style={{ top: "65%" }}>
            <div className="comingsoon-content h-full w-[60vw] mx-auto">
              <p className='sm:text-xl md:text-3xl lg:text-5xl'>Meet The Forex Universe Revolution</p>
            </div>
          </div>
          <div className='absolute bottom-5 right-6 md:bottom-[36px] md:right-[76px]'>
            <p className='text-[9px] lg:text-[16px] text-maincolor font-Livvik flex items-center font-bold'>Hosted by
              <span className='text-base lg:text-3xl'><span className='text-white'>&nbsp; CURRENCY</span>306</span>
            </p>
          </div>
        </div>
        <div className='hero-text text-center mt-12 mb-20'>
          <h3 className='flex items-center flex-col lg:block text-lg font-gillRoy700 text-maincolor'>20 - 21 February 2024
            <span className='font-gillRoy400 text-black'> The Ritz-Carlton, Dubai</span>
            <Button className="button font-gillRoy700 bg-maincolor text-white ms-6 w-fit">Book Ticket Now</Button>
          </h3>
        </div>
      </section>
      {/* <Introduction /> */}
      <RoleCards />
      <Attendees />
      <Partners selectedIndex = {selectedIndex}/>
      {
        mobile ? <SpeakerCardM selectedIndex = {selectedIndex}/> : <SpeakerCard selectedIndex = {selectedIndex}/>
      }
      <PreviousSponsors selectedIndex = {selectedIndex}/>
      <CommunityPartners />
      <MediaPartners />
      <EventTable selectedIndex = {selectedIndex}/>
    </>
  )
}

export default Dubai